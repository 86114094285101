@import "./src/style/theme";

.SendButton {
  @include btn-fill;
  height: 40px;
  line-height: 38px;
  font-size: 14px;
  padding: 0 20px;
  margin-left: $ds-spacing-175;
}
