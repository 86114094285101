@import "./src/style/theme";

.JobOffers {
  position: relative;
  padding-bottom: 0;
  overflow-x: hidden;

  @media (min-width: $min-width-st) {
    &:before {
      content: "";
      width: 850px;
      height: 874px;
      background: url("../../../assets/img/blob-deco.svg") no-repeat;
      position: absolute;
      right: -146px;
      top: -365px;
    }
  }

  &-filters-bottom,
  &-filters {
    @include container();
  }

  &-filters {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 16px;
    padding-top: 32px;

    @media (min-width: $min-width-st) {
      padding-bottom: 32px;
    }
  }

  &-filters-bottom {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  .forOffers {
    margin-left: 0;

    div {
      width: 136px !important;
      height: 60px !important;
    }
  }

  &-refer-button {
    height: 40px !important;
    line-height: 40px !important;
    padding: 0 32px;
    margin: 0;
  }
}

@media (max-width: $max-width-xs) {
  .JobOffers {
    padding: 0;

    .UploadButton {
      margin: 20px 0;
    }
  }

  .JobsOffersList {
    margin: 50px 0 10px;
  }

  .JobOffers-dropdownList {
    margin-left: -20px;
    display: flex;
    justify-content: space-around;
  }
}

@media (max-width: $max-width-xxs) {
  .JobsOffersList {
    margin: 0 0 10px;
  }

  .JobOffers .UploadButton {
    font-size: $font-small-size;
  }
}