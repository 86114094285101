@import "./src/style/theme";

.pageHeaderWrapper {
  border-bottom: 1px solid $bright-gray;
}

.pageHeader {
  @include container;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-top: $ds-spacing-400;
  padding-bottom: $ds-spacing-400;
  position: relative;

  &__imgWrapper {
    display: block;
    margin-right: 30px;
  }

  &__img {
    display: block;
    width: 117px;
    height: auto;
  }

  &__title {
    cursor: default;
    display: block;
    color: $purple;
    @include font(main-header);
  }
}

.tabActive {
  .pageHeader {
    &__imgWrapper:focus {
      @include outline(4px);
    }
  }
}
