@import "./src/style/theme";

.Spinner {
  margin: 0 auto;
  border: 10px solid $bright-gray;
  border-radius: 50%;
  border-top: 10px solid $purple;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin-top: 66px;
  margin-bottom: 66px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
