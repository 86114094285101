@import "./src/style/theme";

.ActionButton {
  display: inline-block;
  position: relative;

  div {
    position: absolute;
    top: 0;
    left: 0;
  }
}
