@import "./src/style/theme";

.OpenFiltersOnMobile {
  background-color: $bright-gray;
  color: $dark-charcoal;
  height: $input-height;
  border-radius: $input-radius;
  margin-left: 25px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;

  &-text {
    @include font(input);

    margin-left: 15px;
    color: $dark-charcoal;
  }
}
