@import "./src/style/theme";

.Benefits {
  background-color: $white;
  padding-bottom: $ds-spacing-800;

  @media (max-width: $min-width-st) {
    padding-bottom: 0;
  }

  &-title {
    width: 70%;

    @media (max-width: $min-width-st) {
      width: 90%;
    }
    margin: 0 auto;
    @include font(send-cv-cta-text);
    padding: 32px 0;

    @media (max-width: $min-width-xs) {
      padding-bottom: 24px;
    }
  }

  &-listOfBenefits {
    width: 70%;

    @media (max-width: $min-width-st) {
      width: 90%;
    }
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-column {
    width: calc(50% - 10px);

    @media (max-width: $min-width-xs) {
      width: 100%;
    }
  }
}
