@import "./src/style/theme";

.ThankBox {
  background: $bright-gray;
  margin: 50px 0;
}

.ThankBox-paragraph {
  font-size: $font-body-size;
  margin: 0 auto;
  max-width: 480px;
}

.ThankBox-header {
  margin-bottom: 32px;
  span {
    color: $purple;
  }
}
