@import "./src/style/theme";

.SendCVSuccess {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: center;
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
  padding: 40px 0;

  &-text {
    text-align: center;
    font-weight: $font-p-regular;
    line-height: 1.2;

    &--mid {
      font-size: 30px;
      font-weight: $font-p-thin;
      margin: 10px 0;
    }

    &--mid-wide {
      font-size: 2em;
      margin: 10px 0;
      font-weight: $font-p-extra-bold;
    }

    &-infoWrapper {
      margin: 0 auto 30px;
      max-width: 768px;

      p {
        margin-bottom: 10px;
      }

      a {
        color: $purple;
      }
    }
    &-infoWrapper-newsletter {
      margin-top: 20px;
      border-top: 1px solid black;
      padding-top: 20px;
    }
  }

  &-img {
    height: 61px;
    width: 64px;
    margin: 50px auto;
  }

  &-buttonWrapper {
    text-align: center;
    margin: $ds-spacing-400 0;
  }

  &-button {
    @include btn-fill;
    font-size: $font-body-size;
    line-height: 47px;
    font-weight: $font-p-bold;
    height: 48px;
    display: inline-block;
    padding: 0 20px;
  }

  @media screen and (max-width: $max-width-xs) {
    &-text {
      width: 100%;
      margin: 40px 0 0;
    }
  }

  &-privacyPolicy {
    color: $purple;
    text-decoration: none;
    margin-left: 0.5em;
  }
}

.tabActive {
  .SendCVSuccess {
    a:focus {
      @include outline();
    }
  }

  .SendCVSuccess-buttonWrapper {
    a:focus {
      @include outline(4px);
    }
  }
}
