@import "./src/style/theme";

.GetToKnowUs-image {
  cursor: pointer;
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.GetToKnowUs-link {
  @include reset-button();
  display: flex;
  justify-content: center;
  position: relative;
}

.GetToKnowUs-text {
  @include font(get-to-know);
  bottom: 70px;
  z-index: 1;
  position: absolute;
  color: $dark-charcoal;
}

.GetToKnowUs-paragraph {
  display: flex;
  justify-content: center;

  &:before {
    background: none;
  }
}

.GetToKnowUs-iframe {
  width: 95vw;
  height: 53.44vw;

  @media (min-width: $min-width-sm) {
    width: 55vw;
    height: 30.94vw;
  }
}
