@import "../config/colors";

.Button {
  margin: 0;
  padding: 10px 0;
  min-width: 130px;
  background: $purple;
  color: $white;
  text-transform: uppercase;
  font-weight: $font-p-extra-bold;
  cursor: pointer;
  outline: none;
  text-align: center;
  font-size: $font-base-size;
  line-height: 18px;
  border: none;

  @media (max-width: 780px) {
    font-size: $font-base-size;
    line-height: 15px;
  }
}

.Button--small {
  font-size: 12px;
  line-height: 15px;
  padding: 10px 30px;
}
