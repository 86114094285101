@import "./src/style/theme";

.Benefit {
  padding: 16px 19px;
  color: $dark-charcoal;
  position: relative;
  z-index: 3;
  border: 1px solid $bright-gray;
  border-radius: 14px;
  background-color: $white;

  &:nth-child(2) {
    margin-top: 20px;
  }

  @media (max-width: $min-width-xs) {
    margin-top: 20px;
  }

  &-mainInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  &-icon {
    display: block;
    width: 34px;
    height: 34px;
    margin-right: 24px;
  }

  &-title {
    @include font(benefit-title);
  }

  &-extraInfo {
    margin-top: 4px;
    padding-left: 58px;
    @include font(benefit-extra);

    overflow: hidden;
    transition: 0.5s 0s max-height;
    max-height: 1000px;

    li {
      position: relative;
      padding-left: $ds-spacing-175;
      margin-bottom: $ds-spacing-150;

      &::before {
        content: "";
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: $dark-charcoal;
        position: absolute;
        top: 14px;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }

  a {
    color: $purple;
  }
}
