@import "./src/style/theme";

.InsightPopup {
  position: relative;
  padding: 30px;
  display: flex;
  max-width: 360px;
  min-height: 100%;
  background: $bright-gray;
  border-radius: 8px;

  @media (max-width: $max-width-xxs) {
    border-radius: 0;
    max-width: 100%;
  }
}

.InsightPopup-image {
  min-width: 70px;
  height: 70px;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.InsightPopup-close {
  background: transparent;
  position: absolute;
  right: 5px;
  top: 5px;
  text-align: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  cursor: pointer;
  outline: none;

  &:after {
    content: "\D7";
    position: relative;
    top: -9px;
    font-size: 30px;
    outline: none;
  }
}

.InsightPopup-copyWrapper {
  display: flex;
  flex-direction: column;

  @media (max-width: $max-width-xxs) {
    width: 100%;
    align-items: center;
  }
}

.InsightPopup-title {
  margin-bottom: 20px;
  flex-grow: 1;
}

.InsightPopup-description {
  line-height: 1.5em;
  margin-bottom: 20px;
}

.InsightPopup-button {
  display: inline-block;
  padding: 10px 30px;
  background: $purple;
  color: $white;
  text-transform: uppercase;
  font-weight: 800;
  cursor: pointer;
  outline: none;
  text-align: center;
  line-height: 18px;
  border: none;
  width: 100%;

  @media (max-width: $max-width-xxs) {
    max-width: 280px;
  }
}

.InsightPopup--centered {
  .InsightPopup-copyWrapper {
    align-items: center;
  }
}

.tabActive {
  .InsightPopup-button:focus {
    @include outline(4px);
  }

  .InsightPopup-close:focus {
    @include outline(-2px);
  }
}
