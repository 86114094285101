@import "./src/style/theme";

.AboutUsContent {
  p {
    @include font(widget-cta-text);
    display: block;
    margin-bottom: $ds-spacing-175;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &-textWithBtn {
    margin-right: $ds-spacing-150;
  }

  p:nth-child(1) {
    display: block;
  }

  &-showBtn {
    display: inline-block;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    font-weight: bold;
  }
}
