@import "./src/style/theme";

.Footer {
  @include font(extra-small);
  width: 100%;
  line-height: 24px;
  color: $black;
  background-color: $bright-gray;
  position: relative;

  &-list li {
    display: inline-block;

    @media (max-width: $max-width-xxs) {
      display: list-item;
    }
  }

  &-cities {
    @include container();
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $max-width-xxs) {
      flex-direction: column;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 88px;
      left: 0;
      width: 100%;
      height: 1px;
      opacity: 0.09;
      background-color: $sonic-silver;

      @media (max-width: $max-width-xs) {
        bottom: 75px;
      }
    }
  }

  &-city {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    color: $dark-charcoal;

    &:nth-child(2) {
      margin: 0 200px;

      @media (max-width: $max-width-sm) {
        margin: 0 100px;
      }

      @media (max-width: $max-width-xs) {
        margin: 0 50px;
      }

      @media (max-width: $max-width-xxs) {
        margin: 64px 0;
      }
    }
  }

  &-cityName {
    margin-bottom: 16px;
    @include font(send-cv-cta-button);
    cursor: default;
  }

  &-contact-phone {
    margin-top: 16px;
  }

  &-contact-item,
  &-contact-phone {
    @include font(footer-text);
  }

  &-links {
    @include container();
    display: flex;
    padding-top: 32px;
    padding-bottom: 32px;
    justify-content: space-between;

    @media (max-width: $max-width-xs) {
      padding-top: 50px;
      padding-bottom: 30px;
      flex-direction: column;
    }
  }

  &-informations {
    display: flex;
    @media (max-width: $max-width-xs) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 24px;
      > * {
        margin-bottom: 32px;
      }
    }
  }

  &-copyrights,
  &-privacyPolicy {
    margin-left: 30px;
    @media (max-width: $max-width-sm) {
      margin-left: 15px;
    }
    @media (max-width: $max-width-xs) {
      margin-left: 0;
    }
  }

  &-icon {
    fill: $red;
    display: inline-block;
    margin-right: 5px;
  }

  &-privacyPolicy {
    color: inherit;

    &:focus {
      outline: none;
    }
  }

  &--mainPage {
    position: absolute;
    bottom: 0;
    z-index: 5;
    color: $white;
    padding-top: 30px;
    padding-bottom: 30px;
    margin: auto;
    left: 0;
    right: 0;
  }

  &--whiteBackground {
    background: $white;
  }

  &-link {
    margin-left: 30px;
    color: inherit;

    &:focus {
      outline: none;
    }

    @media (max-width: $max-width-xxs) {
      margin-left: 0;
    }
  }
}

.tabActive {
  .Footer {
    &-privacyPolicy:focus,
    &-link:focus {
      @include outline();
    }
  }
}
