@import "./src/style/theme";

.NoResults {
  @include container();
  text-align: center;
  cursor: default;
  margin-top: 66px;
  opacity: 0;
  animation: 0.7s show forwards;
  color: #333333;
  &-header {
    @include font(send-cv-cta-text);
    margin-bottom: 32px;
  }
}

@keyframes show {
  100% {
    opacity: 1;
  }
}
