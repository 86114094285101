@import "./src/style/theme";

.OpenMainCarousel {
  background-color: $white;
  padding: $ds-spacing-400 0;
  border-bottom: 1px solid $bright-gray;

  &-content {
    width: 70%;

    @media (max-width: $min-width-st) {
      width: 90%;
    }

    margin: 0 auto;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $saxon-gray;
      opacity: 0.65;
      border-radius: 4px;
    }
  }

  &-bgcImage {
    display: block;
    width: 100%;
    border-radius: 4px;
  }

  &-ctaSection {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }

  &-title {
    color: $white;
    cursor: default;
    @include font(send-cv-cta-text);
    text-align: center;
  }

  &-button {
    cursor: pointer;
    @include font(filter-button);
    display: block;
    margin: $ds-spacing-200 auto 0;
    color: $white;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid $white;
    border-radius: 4px;
    text-transform: uppercase;
    padding: $ds-spacing-175 $ds-spacing-400;
    transition: 0.3s border-color;

    &:hover {
      border-color: $purple;
    }
  }
}
