@mixin full-page-section() {
  width: 100%;
  height: 100vh;
}

@mixin full-page-with-margins($minHeight: 90vh) {
  width: auto;
  margin: 0 $pgs-page-side-margin;
  min-height: $minHeight;
}

@mixin center-content() {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@mixin vertical-center-content() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@mixin center-absolute() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@mixin match-parent-absolute() {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

@mixin object-fit-parent-cover($ratio: 16/9) {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;

  @media (min-aspect-ratio: 16/9) {
    width: 100%;
    height: auto;
  }
  @media (max-aspect-ratio: 16/9) {
    width: auto;
  }
}

@mixin text-align-flex($vertical: top, $horizontal: left) {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @if $vertical == bottom {
    justify-content: flex-end;
  } @else if $vertical == top {
    justify-content: flex-start;
  } @else if $horizontal == left {
    align-items: flex-start;
  } @else if $vertical == right {
    align-items: flex-end;
  } @else if $vertical == center {
    align-items: center;
  } @else {
    justify-content: center;
  }

  @if $horizontal == left {
    align-items: flex-start;
  } @else if $horizontal == right {
    align-items: flex-end;
  } @else if $horizontal == center {
    align-items: center;
  } @else {
    align-items: center;
  }
}

@mixin technology-card {
  position: relative;
  width: 16.66%;
  border-right: 2px solid #f5f5f5;
  border-bottom: 2px solid #f5f5f5;
  height: 293px;
  cursor: pointer;
  background-color: $white;
}

@mixin abs-match-parent() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin btn-fill {
  overflow: hidden;
  height: 60px;
  background-color: $purple;
  color: $white;
  text-align: center;
  line-height: 60px;
  font-size: $font-body-size;
  font-weight: $font-p-semi-bold;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  position: relative;
  margin-left: 0;
  border: 2px solid $purple;
  border-radius: $btn-radius;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &:before {
    display: inline-block;
    height: 100%;
    content: "";
    vertical-align: middle;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
    transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
    -o-transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
    transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
    transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s,
      -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
    z-index: 0;
    border-radius: $btn-radius;
  }

  span {
    position: relative;
    z-index: 1;
    -webkit-transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
    transition: -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    display: inline-block;
  }

  &:disabled {
    cursor: default;
    background: $silver-sand;
    color: $white;
    border-color: $silver-sand;
  }

  &:not([disabled]):hover {
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    color: $purple;
    transform: translateY(-6px);

    &::after {
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transition-delay: 0s;
      -o-transition-delay: 0s;
      transition-delay: 0s;
    }

    span {
      -webkit-transform: translateX(5px);
      -ms-transform: translateX(5px);
      transform: translateX(5px);
      -webkit-transition-delay: 0.075s;
      -o-transition-delay: 0.075s;
      transition-delay: 0.075s;
    }
  }
}

@mixin btn-no-fill {
  overflow: hidden;
  height: 60px;
  padding: 0 20px;
  background: none;
  color: $purple;
  text-align: center;
  line-height: 60px;
  font-size: $font-body-size;
  font-weight: $font-p-semi-bold;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  position: relative;
  margin-left: 0;
  border: 2px solid $purple;
  border-radius: $btn-radius;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;

  span {
    position: relative;
    z-index: 1;
    transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(0);
    display: inline-block;
  }
  &:hover {
    transition-delay: 0s;
    color: $white;
  }

  &:before {
    display: inline-block;
    height: 100%;
    content: "";
    vertical-align: middle;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $purple;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
    z-index: 0;
  }

  &:disabled {
    cursor: default;
    background: $white;
    color: $silver-sand;
    border: 2px solid $silver-sand;
  }

  &:not([disabled]):hover {
    transition-delay: 0s;
    color: $white;

    &::after {
      transform: scaleX(1);
      transition-delay: 0s;
    }

    span {
      transform: translateX(5px);
      transition-delay: 0.075s;
    }
  }
}

@mixin btn-no-fill-white {
  height: 60px;
  padding: 0 20px;
  min-width: 120px;
  background: none;
  color: $white;
  text-align: center;
  line-height: 60px;
  font-size: $font-body-size;
  font-weight: $font-p-semi-bold;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  position: relative;
  margin-left: 0;
  border: 2px solid $white;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;

  span {
    position: relative;
    z-index: 1;
    transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(0);
    display: inline-block;
  }
  &:hover {
    transition-delay: 0s;
    color: $purple;
  }

  &:before {
    display: inline-block;
    height: 100%;
    content: "";
    vertical-align: middle;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
    z-index: 0;
    border-radius: $btn-radius;
  }

  &:hover {
    transition-delay: 0s;
    color: $purple;

    &::after {
      transform: scaleX(1);
      transition-delay: 0s;
    }

    span {
      transform: translateX(5px);
      transition-delay: 0.075s;
    }
  }
}

@mixin image-responsive {
  display: block;
  max-width: 100%;
}

@mixin responsive-svg-container($svg-selector: svg) {
  position: relative;
  width: 100%;
  // for IE only
  padding: 0;
  height: 0;
  // --
  padding-bottom: 50%;
  // adjust height to aspect ratio
  min-height: 48vw;
  overflow: hidden;
  // override in all browser but IE<=11
  @supports (fill: $purple) {
    padding-bottom: 0;
    height: auto;
  }

  #{$svg-selector} {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}

@mixin outline($offset: 2px, $color: $purple) {
  outline: 2px solid $color !important;
  outline-offset: $offset !important;
  transition: all 100ms;
  box-shadow: 0 0 0 0 $color;
}
