@mixin input-reset {
  appearance: none;
  background: transparent;
  border: 0;
  outline: none;
  padding: 0;

  &:focus,
  &:active {
    outline: none;
  }
}
@mixin list-reset() {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin reset-button() {
  all: unset;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}
