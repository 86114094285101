@import "./src/style/theme";

.SocialMedia {
  display: flex;
  align-items: center;
  line-height: 1;

  @media (max-width: $max-width-xs) {
    flex-direction: column;
  }
}

.SocialMedia-header {
  @include font(extra-small);
  line-height: 16px;
  white-space: nowrap;
}

.SocialMedia-list {
  text-align: center;
}

.SocialMedia-item {
  display: inline-block;
  cursor: pointer;
}

.SocialMedia-logo {
  width: 15px;
  height: 15px;
  display: block;
  margin: 0 10px;

  &:focus {
    outline: none;
  }

  @media (max-width: $max-width-sm) {
    margin: 0 5px;
  }

  @media (max-width: $max-width-xs) {
    margin: 0 8px;
  }
}

.SocialMedia:not(.SocialMedia--large) {
  @media (max-width: $max-width-xs) {
    flex-direction: column;
    align-items: flex-start;
    .SocialMedia-header {
      width: 100%;
      margin-bottom: 16px;
    }
    .SocialMedia-list {
      width: calc(100% + 16px);
      max-width: 420px;
      margin: 0 -8px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.SocialMedia--large {
  flex-direction: column;

  .SocialMedia-header {
    @include font(section-title);
  }

  .SocialMedia-list {
    margin-top: 60px;

    .SocialMedia-logo {
      width: 40px;
      height: 40px;
      display: block;
      margin: 0 20px;

      @media (max-width: $max-width-xs) {
        width: 20px;
        height: 20px;
        margin: 0 10px;
      }
    }
  }
}

.tabActive {
  .SocialMedia-logo:focus {
    @include outline();
  }
}
