@import "./src/style/theme";

.UploadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 94px;
  position: relative;
}

.Btn-fill {
  @include btn-fill;
}
