@mixin isCentered() {
  text-align: center;
}

@mixin isOrange() {
  color: #ff7626;
}

@mixin isBold() {
  font-weight: $font-p-extra-bold;
}

@mixin isHidden() {
  display: none;
}

@mixin container() {
  width: 100%;
  max-width: 1352px;
  display: block;
  margin: auto;
  padding: 0 20px;
}

@mixin row() {
  @media (max-width: $max-width-xs) {
    .row {
      margin: 0 -10px;
    }
  }
}

@mixin fullWidth() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin toCenter() {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
