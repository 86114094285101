@import "./src/style/theme";

.ConsentInput-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ConsentInput-wrapper-labelTitle {
  display: inline;
  color: $validation-red;
}

.ConsentInput-label {
  color: $dark-charcoal;
  @include font(input-label);
  margin-bottom: $ds-spacing-175;
}

.ConsentInput-input {
  @include input-reset();
  @include font(input);
  background-color: $white;
  color: $dark-charcoal;
  width: 100%;
  padding: 0 $ds-spacing-200;
  border: solid 1px $silver-sand;
  border-radius: $input-radius;
  height: $input-height;

  &:focus {
    @include outline(-2px);
  }

  &:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px $bright-gray inset;
  }

  &::placeholder {
    @include font(input-placeholder);
    color: $sonic-silver;
  }

  &.hasValue {
    color: $dark-charcoal;
  }
}

.ConsentInput-invalidDataInfo {
  @include font(input-label);
  display: block;
  cursor: default;
  color: $validation-red;
  margin-top: $ds-spacing-175;
}

.tabActive {
  .ConsentInput-input:focus {
    @include outline(-2px);
  }
}
