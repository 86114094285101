@import "./src/style/theme";

.ModalBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $modal-background;
  z-index: 29;
  overflow: auto;
  height: 100vh;
}

.Modal {
  width: 40%;
  background: #fff;
  color: #333333;
  transition: all 0.4s ease;
  border-radius: 4px;
  margin: 5% auto;

  @media (max-width: $min-width-st) {
    margin: 5% auto 100px;
    width: 90%;
  }

  @media (max-width: $min-width-xs) {
    margin: 3% auto 100px;
    width: 94%;
  }

  &-header {
    text-align: center;
    @include font(send-cv-cta-text);
    margin-top: $ds-spacing-400;
    cursor: default;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .is-disabled {
      background-color: $platinum;
      cursor: default;
    }
  }

  &-errorMessage {
    padding: 0 $ds-spacing-400 $ds-spacing-200 $ds-spacing-400;
    cursor: default;
    color: $validation-red;
    @include font(input-label);
  }

  &-separated {
    padding: 0 $ds-spacing-400;
  }

  &-consents {
    padding: $ds-spacing-400;
  }

  &-consents-annotations {
    line-height: normal;
    margin-bottom: $ds-spacing-400;
  }

  &-consents-header {
    @include font(input-label);
    color: $dark-charcoal;
    margin-bottom: $ds-spacing-200;
  }

  &-buttonsWrapper {
    text-align: center;
    margin-bottom: $ds-spacing-400;
  }
}

@mixin dashed-border(
  $dashlength,
  $spacelength,
  $borderwidth,
  $color,
  $position: top,
  $round: true
) {
  $per: percentage($dashlength / ($dashlength + $spacelength));
  @if $position == "top" {
    @if $round == true {
      background: radial-gradient(
          circle at 50% center,
          $color 0%,
          $color $per,
          rgba(0, 0, 0, 0) $per
        ),
        radial-gradient(circle at center 50%, $color 0%, $color $per, rgba(0, 0, 0, 0) $per);
      background-position: 0 $position, 0 $position;
    } @else {
      background: linear-gradient(to right, $color 0%, $color $per, rgba(0, 0, 0, 0) $per),
        linear-gradient(to bottom, $color 0%, $color $per, rgba(0, 0, 0, 0) $per);
      background-position: 0 $position, 0 $position;
    }
  } @else {
    @if $round == true {
      background: radial-gradient(
          circle at 50% center,
          $color 0%,
          $color $per,
          rgba(0, 0, 0, 0) $per
        ),
        radial-gradient(circle at center 50%, $color 0%, $color $per, rgba(0, 0, 0, 0) $per);
      background-position: 100% $position, 100% $position;
    } @else {
      background: linear-gradient(to left, $color 0%, $color $per, rgba(0, 0, 0, 0) $per),
        linear-gradient(to top, $color 0%, $color $per, rgba(0, 0, 0, 0) $per);
      background-position: 100% $position, 100% $position;
    }
  }
  background-size: ($dashlength + $spacelength) $borderwidth,
    $borderwidth ($dashlength + $spacelength);
  background-repeat: repeat-x, repeat-y;
}

.DashedBorders {
  $dashes-width: 3px;
  position: relative;
  border-radius: 1px;

  &:before,
  &:after {
    content: "";
    display: block;
    top: -$dashes-width;
    left: -$dashes-width;
    right: -$dashes-width;
    bottom: -$dashes-width;
    position: absolute;
    border: $dashes-width solid transparent;
    box-sizing: border-box;
    pointer-events: none;
  }
  &:before {
    @include dashed-border(9px, 9px, $dashes-width, $purple, top, false);
    background-origin: border-box;
  }
  &:after {
    @include dashed-border(9px, 9px, $dashes-width, $purple, bottom, false);
    background-origin: border-box;
  }

  .Btn-link {
    color: $purple;
    font-size: $font-base-size;
    font-weight: $font-p-semi-bold;
    text-decoration: underline;
    background: transparent;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    padding: 0 16px;

    &:focus {
      outline: none;
    }
  }

  &.step-error {
    &:before {
      @include dashed-border(9px, 9px, $dashes-width, $validation-red, top, false);
      background-origin: border-box;
    }
    &:after {
      @include dashed-border(9px, 9px, $dashes-width, $validation-red, bottom, false);
      background-origin: border-box;
    }
  }
}

.FileDropzone {
  margin: $ds-spacing-400 auto;
  padding: $ds-spacing-600 $ds-spacing-200;
  width: calc(100% - 6px);

  &.loaded {
    padding: 0;

    &::before,
    &::after {
      display: none;
    }

    .Btn-link {
      display: none;
    }
  }

  @media (max-width: $min-width-st) {
    padding: 0;
  }

  &-unloaded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-loadedHeader {
    color: $dark-charcoal;
    @include font(input-label);
    margin-bottom: $ds-spacing-175;
  }

  &-loadedContentWrapper {
    border-bottom: 1px solid $dark-charcoal;
    background-color: $bright-gray;
    @include font(input);
    color: $dark-charcoal;
    padding: $ds-spacing-150 $ds-spacing-175;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-btn {
    display: block;
    margin: 0 auto;
    background: $purple;
    color: $white;
    text-transform: uppercase;
    @include font(filter-button);
    padding: $ds-spacing-175 $ds-spacing-200;
    border-radius: 24px;
    cursor: pointer;
  }

  &-removeBtn {
    display: block;
    position: relative;
    cursor: pointer;
    width: 16px;
    height: 16px;
    background-color: $bright-gray;

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: $dark-charcoal;
      width: 100%;
      height: 1px;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.open-modal {
  @include react-transition-fade(300ms, 300ms);
}

.tabActive {
  .FileDropzone {
    .Btn-link:focus {
      @include outline();
    }
  }
}
