@import "./src/style/theme";

.Jumbotron {
  &-imageWrapper {
    position: relative;
    border-radius: 0 0 140px 0;
    overflow: hidden;

    @media (max-width: $min-width-st) {
      border-radius: 0 0 90px 0;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $dark-charcoal;
      opacity: 0.42;
    }
  }

  &-image {
    display: block;
    width: 100%;
    aspect-ratio: 2 / 1;
  }

  &-descriptionText {
    position: absolute;
    bottom: $ds-spacing-400;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 70%;

    @media (max-width: $min-width-st) {
      width: 90%;
      bottom: 5vw;
    }
    cursor: default;
    z-index: 2;
  }

  &-title {
    @include font(subtitle);
    border-bottom: 1px solid rgba($white, 0.19);
    width: 100%;
    color: $white;
    padding-bottom: $ds-spacing-375;
    margin-bottom: $ds-spacing-375;

    @media (max-width: $min-width-xs) {
      padding-bottom: $ds-spacing-175;
      margin-bottom: $ds-spacing-175;
    }
  }

  &-salary {
    color: $white;
    @include font(subtitle-small-jumbotron);

    &:nth-last-child(1) {
      margin-top: $ds-spacing-150;
    }
  }

  &-skills {
    @include font(skill);
    background-color: $white;
    color: $dark-charcoal;
    cursor: default;
    @media (max-width: $min-width-xs) {
      text-align: center;
    }
  }

  &-requiredSkills {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: $ds-spacing-375 15%;
    @media (max-width: $min-width-st) {
      padding: $ds-spacing-200 5%;
    }

    @media (max-width: $min-width-xs) {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &-requiredSkillWrapper {
    position: relative;
    z-index: 3;
    padding: $ds-spacing-150 0;

    @media (min-width: $min-width-lg) {
      &.hovered {
        background-color: $silver-sand;

        .Jumbotron-requiredSkillLabel {
          display: block;
        }
      }
    }
  }

  &-requiredSkill {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    border-radius: 4px;
    padding: 0 $ds-spacing-175;
  }

  &-splittedSkillsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  &-splittedSkillName {
    text-align: end;

    &:not(:first-child) {
      padding-left: 3px;
    }
  }

  &-requiredSkillProgressBar {
    flex-shrink: 0;
    height: 8px;
    width: 8vw;
    @media (max-width: $min-width-st) {
      width: 15vw;
    }
    @media (max-width: $min-width-xs) {
      width: 40vw;
    }
    background-color: $platinum;
    margin-left: $ds-spacing-400;
    border-radius: 4px;

    &::after {
      content: "";
      display: block;
      background-color: $purple;
      width: 0;
      height: 100%;
      border-radius: 4px;
    }
  }

  @for $i from 1 to 6 {
    &-requiredSkillProgressBar.level#{$i} {
      &::after {
        width: $i * 20%;
      }
    }
  }

  &-requiredSkillLabel {
    @include font(skill-label);
    display: none;
    background-color: $white;
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 45px;
    width: 100%;
    padding: $ds-spacing-200 $ds-spacing-375;
    border-radius: 4px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.16);

    &::before {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background-color: $white;
      position: absolute;
      z-index: 3;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%) rotate(45deg);
    }
  }

  &-optionalSkills {
    display: flex;
    background-color: $bright-gray;
    padding: 6px 28px;
    border-radius: 20px;
    margin: auto 15%;

    @media (max-width: $min-width-st) {
      margin: auto 5%;
    }

    @media (max-width: $min-width-xs) {
      text-align: left;
    }
  }

  &-optionalSkillLabel {
    @include font(optional-skill-label);
    padding-right: $ds-spacing-175;
  }

  &-optionalSkill {
    @include font(optional-skill);
    &:not(:nth-last-child(1)) {
      &::after {
        content: ", ";
      }
    }
  }
}
