// Typography
$font-stack: "Proxima Nova", sans-serif;

$font-p-thin: 100;
$font-p-light: 300;
$font-p-regular: 400;
$font-p-semi-bold: 600;
$font-p-bold: 700;
$font-p-extra-bold: 800;
$font-p-black: 900;

$font-small-size: 12px;
$font-base-size: 14px;
$font-body-size: 16px;

$font-levels: (
  "section-title-large": (
    font-size: 42px,
    line-height: 54px,
    font-weight: $font-p-extra-bold,
  ),
  "section-title-medium": (
    font-size: 34px,
    line-height: 51px,
    font-weight: $font-p-extra-bold,
  ),
  "section-title": (
    font-size: 34px,
    line-height: 43px,
    font-weight: $font-p-extra-bold,
  ),
  "subtitle-small": (
    font-size: 20px,
    line-height: 25px,
    font-weight: $font-p-extra-bold,
  ),
  "subtitle-small-jumbotron": (
    font-size: 16px,
    line-height: 20px,
    font-weight: $font-p-bold,
  ),
  "subtitle": (
    font-size: 24px,
    line-height: 29px,
    font-weight: $font-p-bold,
  ),
  "body": (
    font-size: $font-base-size,
    line-height: 28px,
    font-weight: $font-p-light,
  ),
  "button": (
    font-size: $font-base-size,
    line-height: 18px,
    font-weight: $font-p-extra-bold,
  ),
  "extra-small": (
    font-size: $font-base-size,
    line-height: $font-base-size,
    font-weight: $font-p-regular,
  ),
  "main-header": (
    font-size: 24px,
    line-height: 30px,
    font-weight: $font-p-bold,
  ),
  "send-cv-cta-text": (
    font-size: 21px,
    line-height: 28px,
    font-weight: $font-p-bold,
  ),
  "send-cv-cta-button": (
    font-size: 16px,
    line-height: 28px,
    font-weight: $font-p-bold,
  ),
  "job-link-title": (
    font-size: 16px,
    line-height: 28px,
    font-weight: $font-p-bold,
  ),
  "job-link-basic-text": (
    font-size: $font-base-size,
    line-height: 28px,
    font-weight: $font-p-regular,
  ),
  "job-link-cities-text": (
    font-size: $font-small-size,
    line-height: 24px,
    font-weight: $font-p-regular,
  ),
  "input-label": (
    font-size: $font-base-size,
    line-height: 18px,
    font-weight: $font-p-semi-bold,
  ),
  "input": (
    font-size: 16px,
    line-height: 28px,
    font-weight: $font-p-semi-bold,
  ),
  "input-placeholder": (
    font-size: 16px,
    line-height: 28px,
    font-weight: $font-p-regular,
  ),
  "select-option": (
    font-size: $font-base-size,
    line-height: 28px,
    font-weight: $font-p-regular,
  ),
  "select-option-selected": (
    font-size: $font-base-size,
    line-height: 28px,
    font-weight: $font-p-bold,
  ),
  "filter-button": (
    font-size: $font-base-size,
    line-height: 24px,
    font-weight: $font-p-bold,
  ),
  "benefit-title": (
    font-size: $font-base-size,
    line-height: 28px,
    font-weight: $font-p-semi-bold,
  ),
  "benefit-extra": (
    font-size: $font-base-size,
    line-height: 28px,
    font-weight: $font-p-regular,
  ),
  "skill": (
    font-size: $font-base-size,
    line-height: 28px,
    font-weight: $font-p-bold,
  ),
  "skill-label": (
    font-size: $font-base-size,
    line-height: 20px,
    font-weight: $font-p-regular,
  ),
  "optional-skill-label": (
    font-size: $font-base-size,
    line-height: 28px,
    font-weight: $font-p-bold,
  ),
  "optional-skill": (
    font-size: $font-base-size,
    line-height: 28px,
    font-weight: $font-p-regular,
  ),
  "footer-text": (
    font-size: $font-base-size,
    line-height: 24px,
    font-weight: $font-p-regular,
  ),
  "widget-cta-text": (
    font-size: $font-base-size,
    line-height: 28px,
    font-weight: $font-p-regular,
  ),
  "consent-text": (
    font-size: $font-base-size,
    line-height: 18px,
    font-weight: $font-p-regular,
  ),
  "get-to-know": (
    font-size: 22px,
    line-height: 28px,
    font-weight: $font-p-bold,
  ),
  "paragraph-text-title": (
    font-size: 21px,
    line-height: 28px,
    font-weight: $font-p-bold,
  ),
);

$pgs-page-side-margin: 71px;

// Breakpoints
$min-width-xs: 480px;
$min-width-sm: 780px;
$min-width-md: 992px;
$min-width-st: 1024px;
$min-width-lg: 1200px;
$max-width-xxs: $min-width-xs - 1;
$max-width-xs: $min-width-sm - 1;
$max-width-sm: $min-width-md - 1;
$max-width-md: $min-width-st - 1;
$max-width-st: $min-width-lg - 1;

//Spacing
$ds-spacing-150: 4px;
$ds-spacing-175: 8px;
$ds-spacing-200: 16px;
$ds-spacing-375: 24px;
$ds-spacing-400: 32px;
$ds-spacing-600: 64px;
$ds-spacing-800: 128px;
$ds-spacing-1000: 256px;

//Radius

$input-radius: 20px;
$input-height: 40px;
$ofer-list-radius: 10px;
$ofer-list-cities: 13px;
$btn-radius: 100px;
