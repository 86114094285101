@import "./src/style/theme";

.SendCV {
  &-wave {
    width: 100%;
    object-fit: cover;

    @media (max-width: $min-width-xs) {
      height: 154px;
      object-fit: cover;
    }
  }

  &-wrapper {
    @include container();
    position: relative;
    text-align: center;
  }

  &-ctaText {
    @include font(send-cv-cta-text);
    color: $dark-charcoal;
  }

  &-button {
    @include font(send-cv-cta-button);
    height: 48px;
    width: 138px;
    margin: 32px auto 50px;
  }
}

.tabActive {
  .SendCV {
    &-button:focus {
      @include outline(4px);
    }
  }
}
