@import "./src/style/theme";

.JobsOffersListWrapper {
  position: relative;
  animation: show 0.7s forwards;

  &-backgroundImage {
    position: absolute;
    right: 0;
    bottom: 100px;

    @media (max-width: $min-width-st) {
      display: none;
    }
  }
}

.JobsOffersList {
  @include list-reset();
  @include container();
  padding-top: 66px;
  padding-bottom: 48px;

  @media (max-width: $min-width-st) {
    padding-top: 32px;
  }

  .JobsOffersList-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .JobOfferLink {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $bright-gray;
    border-radius: $ofer-list-radius;
    outline: none;
    transition: 0.3s ease box-shadow;
    background-color: $white;
    position: relative;

    &:hover {
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.13);
      border-top: 1px solid $bright-gray;
    }

    @media (max-width: $min-width-st) {
      flex-direction: column;
      padding: 32px 36px;
    }

    &-title {
      @include font(job-link-title);
      width: 30%;
      color: $dark-charcoal;
      padding-right: 20px;

      @media (max-width: $min-width-st) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 8px;
      }
    }

    &-technology {
      @include font(job-link-basic-text);
      width: 15%;
      color: $dark-charcoal;
      padding-right: 20px;

      @media (max-width: $min-width-st) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 8px;
      }
    }

    &-salary {
      @include font(job-link-basic-text);
      width: 28%;
      color: $dark-charcoal;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @media (max-width: $min-width-st) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tabActive {
  .JobOfferLink:focus {
    z-index: 2;
    @include outline(0);
  }
}