@import "./src/style/theme";

.FiltersModal {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(49, 49, 49, 0.7);

  &-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    background-color: $white;
    padding: 40px;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    @include font(send-cv-cta-button);
    font-family: $font-stack;
    color: $dark-charcoal;
  }

  &-closeBtn {
    display: block;
    width: 16px;
    height: 16px;
    position: relative;
    background-color: $white;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1px;
      height: 23px;
      background-color: $modal-background;
    }

    &::before {
      transform: translate(-50%, -50%) rotateZ(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotateZ(-45deg);
    }
  }

  &-filterBtn {
    display: block;
    outline: none;
    text-transform: uppercase;
    background-color: $white;
    font-family: $font-stack;
    color: $dark-charcoal;
    border: 2px solid $purple;
    border-radius: 24px;
    padding: 8px 24px;
    margin: 64px auto 0;
    @include font(filter-button);
  }
}
