@import "./src/style/theme";

.PrivacyPolicy {
  &-contentWrapper {
    @include container();
    margin-top: $ds-spacing-600;
  }
}

.PrivacyPolicy-content {
  width: 100%;
  max-width: 728px;
  margin: 0 auto;

  @media screen and (max-width: $max-width-md) {
    width: 75%;
  }
  @media screen and (max-width: $max-width-xs) {
    width: 100%;
  }
}

.PrivacyPolicy-heading {
  text-align: center;
  @include font(section-title);

  @media screen and (max-width: $max-width-xs) {
    @include font(subtitle);
  }
}

.PrivacyPolicy-subheading {
  @include font(subtitle);
  margin: 50px 0 0;

  @media screen and (max-width: $max-width-xs) {
    @include font(subtitle-small);
    margin: 50px 0 0;
  }
}

.PrivacyPolicy-paragraph,
.PrivacyPolicy-list {
}

.PrivacyPolicy-paragraph,
.PrivacyPolicy-list--first {
  margin-top: 20px;
}

.PrivacyPolicy-paragraph--inner {
  margin-bottom: 20px;
}

.PrivacyPolicy-link,
.PrivacyPolicy-mail {
  color: $purple;
}

.PrivacyPolicy-list li {
  padding: 5px 0;
}

.PrivacyPolicy-list li:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 10px;
  background: $purple;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.PrivacyPolicy-list--inner {
  margin-left: 30px;

  @media screen and (max-width: $max-width-xs) {
    margin-left: 20px;
  }
}

ol.PrivacyPolicy-list {
  counter-reset: numbers;

  li::before {
    content: counter(numbers) ".";
    counter-increment: numbers;
    background: none;
    transform: none;
    font-weight: bold;
  }
}

.tabActive {
  .PrivacyPolicy-link:focus,
  .PrivacyPolicy-mail:focus {
    @include outline();
  }
}
