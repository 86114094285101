@import "./src/style/theme";

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.JobOfferRedesigned {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  animation: show 0.3s ease-in-out forwards;

  &-firstColumn {
    width: 60%;

    @media (max-width: $min-width-st) {
      width: 100%;
    }
  }

  &-secondColumn {
    width: 40%;

    @media (max-width: $min-width-st) {
      width: 100%;
      background-color: $bright-gray;
      margin-top: 84px;
    }

    @media (min-width: $min-width-xs) and (max-width: $min-width-st) {
      text-align: center;
      padding: 0 5%;
    }
  }

  &-fixedWrapper {
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    padding-top: $ds-spacing-800;
    text-align: center;

    @media (max-width: $min-width-st) {
      position: static;
      width: 100%;
      padding-top: 0;
    }
  }
}
