@import "./src/style/theme";

.ShowJobOffers {
  display: flex;
  margin-bottom: 100px;

  @media (max-width: $max-width-xs) {
    flex-direction: column;
    margin-bottom: 80px;
  }
}

.ShowJobOffers--noBottomMargin {
  margin-bottom: 0;
}

.ShowJobOffers-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;

  @media (max-width: $max-width-md) {
    padding-left: 100px;
    max-width: 400px;
  }

  @media (max-width: $max-width-xxs) {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 500px;
  }

  .Button {
    margin-top: 50px;
    width: 230px;
    padding: 15px 0;
  }
}

.ShowJobOffers-header {
  line-height: 63px;
}

.tabActive {
  .ShowJobOffers-content {
    a:focus {
      @include outline(4px);
    }
  }
}
