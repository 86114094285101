@import "./src/style/utils/helpers";
@import "./src/style/config/variables";
@import "./src/style/mixins/font";

// Proxima Nova Thin

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-thin;
  font-style: normal;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-Thin.ttf") format("truetype");
}

// Proxima Nova Thin Italic

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-thin;
  font-style: italic;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-ThinIt.ttf") format("truetype");
}

// Proxima Nova Light

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-light;
  font-style: normal;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-Light.ttf") format("truetype");
}

// Proxima Nova Light Italic

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-light;
  font-style: italic;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-LightIt.ttf") format("truetype");
}

// Proxima Nova Regular

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-regular;
  font-style: normal;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-Regular.ttf") format("truetype");
}

// Proxima Nova Regular Italic

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-regular;
  font-style: italic;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-RegularIt.ttf") format("truetype");
}

// Proxima Nova Semibold

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-semi-bold;
  font-style: normal;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-Semibold.ttf") format("truetype");
}

// Proxima Nova Semi Bold italic

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-semi-bold;
  font-style: italic;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-SemiboldIt.ttf") format("truetype");
}

// Proxima Nova Bold

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-bold;
  font-style: normal;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-Bold.ttf") format("truetype");
}

// Proxima Nova Bold Italic

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-bold;
  font-style: italic;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-BoldIt.ttf") format("truetype");
}

// Proxima Nova Extra Bold

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-extra-bold;
  font-style: normal;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-Extrabld.ttf") format("truetype");
}

// Proxima Nova Extra Bold Italic

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-extra-bold;
  font-style: italic;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-ExtrabldIt.ttf") format("truetype");
}

// Proxima Nova Extra Bold

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-black;
  font-style: normal;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-Black.ttf") format("truetype");
}

// Proxima Nova Extra Bold Italic

@font-face {
  font-family: "Proxima Nova";
  font-weight: $font-p-black;
  font-style: italic;
  src: url("../../assets/fonts/ProximaNova/ProximaNova-BlackIt.ttf") format("truetype");
}

h1 {
  font-size: 42px;
  line-height: 54px;
  @media (max-width: $max-width-xs) {
    font-size: 34px;
    line-height: 51px;
  }
}

h2 {
  font-size: 34px;
  line-height: 43px;
  @media (max-width: $max-width-xs) {
    font-size: 24px;
    line-height: 30px;
  }
}

h3 {
  font-size: 24px;
  line-height: 30px;
  @media (max-width: $max-width-xs) {
    font-size: $font-body-size;
    line-height: 20px;
  }
}

h4 {
  font-size: 20px;
  line-height: 25px;
  @media (max-width: $max-width-xs) {
    font-size: $font-body-size;
    line-height: 20px;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: $font-p-extra-bold;
}

body {
  @include font(body);
  font-family: "Proxima Nova", sans-serif;
}
