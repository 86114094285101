@import "./src/style/theme";

.RecruitmentTask {
  padding: $ds-spacing-400;
  background-color: $platinum;

  &-header {
    cursor: default;
    @include font(send-cv-cta-button);
    margin-bottom: $ds-spacing-400;
  }

  &-textWrapper {
    cursor: default;
    @include font(skill-label);

    margin-bottom: $ds-spacing-400;
  }
}
