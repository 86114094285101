@import "./src/style/theme";

.CookieInfo {
  width: 100%;
  line-height: 15px;
  background: #ddd;
  color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 43px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 10000;
}

.CookieInfo-text {
  font-size: $font-small-size;
  flex: 0 1 auto;
  margin-right: 20px;
}

.CookieInfo-link {
  font-weight: $font-p-extra-bold;
  color: $purple;
  transition: all 300ms ease;
  text-decoration: underline;
}

.CookieInfo-close {
  @include btn-fill;
  height: 28px;
  line-height: 28px;
  font-size: $font-small-size;
  flex: 0 0 auto;
  box-sizing: content-box;
  padding: 0 5px;
  display: flex;
  align-items: center;

  &:not([disabled]) {
    &:hover {
      span {
        transform: none;
      }
    }
  }
}

.tabActive {
  .CookieInfo-link:focus {
    @include outline();
  }

  .CookieInfo-close:focus {
    @include outline(4px);
  }
}
