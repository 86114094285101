@import "./src/style/theme";

.BackButton {
  position: absolute;
  top: $ds-spacing-400;
  left: 15%;

  @media (max-width: $min-width-st) {
    top: 5vw;
    left: 5%;
  }

  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  display: flex;
  padding: 4px 16px;
  color: $white;
  font-weight: bold;
  cursor: pointer;
}

.BackButton-hide {
  @include a11y-hide();
}
