@import "./src/style/theme";

.BackButtonFooter {
  font-size: $font-small-size;
  height: 77px;
  background: $white;
  color: $black;
  font-weight: $font-p-bold;
  line-height: 22px;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}
