@import "./src/style/theme";

.ContactDetails {
  border-top: 1px solid $bright-gray;
  border-bottom: 1px solid $bright-gray;
  padding: $ds-spacing-400;

  &-nameAndSurnameWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $ds-spacing-200;

    @media (max-width: $min-width-sm) {
      margin-bottom: 0;
    }

    .ConsentInput {
      width: 50%;

      @media (max-width: $min-width-sm) {
        width: 100%;
        margin-bottom: $ds-spacing-200;
      }

      &:nth-child(1) {
        padding-right: $ds-spacing-375;

        @media (max-width: $min-width-sm) {
          padding-right: 0;
        }
      }

      &:nth-child(2) {
        padding-left: $ds-spacing-375;

        @media (max-width: $min-width-sm) {
          padding-left: 0;
        }
      }
    }
  }
}
