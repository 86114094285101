@import "./src/style/theme";

.JobOffersCities {
  width: 24%;
  color: $dark-charcoal;
  @include font(job-link-cities-text);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media (max-width: $min-width-st) {
    width: 100%;
    padding-right: 0;
    justify-content: flex-start;
  }

  &-city {
    background-color: $bright-gray;
    border-radius: $ofer-list-cities;
    padding: 1px 16px;
    margin: 4px 0 4px 12px;

    @media (max-width: $min-width-st) {
      margin: 4px 12px 4px 0;
    }
  }

  &-allOffersBadge {
    margin: 0;
  }

  &-briefInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: $min-width-st) {
      margin: 4px 12px 4px 0;
    }

    @media (min-width: $min-width-lg) {
      &:hover .JobOffersCities-hiddenInfo {
        display: block;
        position: absolute;
        left: 50%;
        top: -48px;
        transform: translateX(-50%);
        border-radius: 4px;
        padding: 6px 16px;
        background-color: $white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);

        &::before {
          content: "";
          display: block;
          background-color: $white;
          position: absolute;
          width: 15px;
          height: 15px;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 50%) rotate(45deg);
        }
      }
    }
  }

  &-hiddenInfo {
    display: none;
    white-space: nowrap;
  }
}
