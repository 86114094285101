@import "./src/style/theme";

.PageNotFound {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.PageNotFound-title {
  @include font(section-title);
  text-align: center;
  margin: 0 auto;
  padding-top: 50px;
}

.PageNotFound-icon {
  display: block;
  margin: 20px auto;
  width: 100%;
  max-width: 360px;
}

.PageNotFound-NavigationLinks {
  margin: auto;
  text-align: center;
}

.PageNotFound-offers {
  @include btn-fill;
  font-size: 16px;
  line-height: 47px;
  font-weight: $font-p-bold;
  height: 48px;
  display: block;
  padding: 0 20px;
}
