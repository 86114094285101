@import "./src/style/theme";

.Breadcrumbs {
  @include container();
  @include font(extra-small);
  padding-top: 20px;
  padding-bottom: 20px;
  color: $purple;
}

.Breadcrumb {
  text-decoration: none;
  color: $purple;

  &:focus {
    outline: none;
  }

  &:after {
    content: "/";
    margin: 0 5px;
  }

  &:last-child {
    &:after {
      content: none;
    }
  }

  &.is-active {
    color: $black;
    cursor: default;
  }
}

.tabActive {
  .Breadcrumb:focus {
    @include outline();
  }
}
