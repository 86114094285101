$purple: #6a1d5f;
$black: #000;
$saxon-gray: #2d2d2d;
$dark-charcoal: #333333;
$sonic-silver: #757575;
$silver-sand: #c6c6c6;
$platinum: #e6e6e6;
$bright-gray: #ebebeb;
$white: #fff;

$red: #ff3535;
$validation-red: #ff4747;

$modal-background: rgba(1, 1, 1, 0.67);
