@import "./src/style/theme";

.ThankImage {
  padding: 48px 0 64px;

  @media (min-width: $min-width-sm) {
    padding: 96px 0 128px;
  }
}

.ThankImage-image {
  max-width: 100%;
  transform: scale(0.7);

  @media (min-width: $min-width-sm) {
    transform: scale(1);
  }
}
