@import "../config/variables";
@import "../utils/helpers";

@mixin font($level) {
  $level-values: get_value_from_map($font-levels, $level, default);
  font-family: $font-stack;
  font-size: map_get($level-values, font-size);
  line-height: map_get($level-values, line-height);
  font-weight: map_get($level-values, font-weight);
}
