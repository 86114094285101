@import "../config/colors";
@import "../common/utilities";
@import "./src/style/theme";

a:not(:active):focus,
button:not(:active):focus {
  @include outline();
}

.Line {
  position: relative;
  margin-bottom: 50px;
  padding-bottom: 0;

  &:after {
    content: "";
    position: relative;
    display: block;
    bottom: 0;
    height: 100px;
    width: 2px;
    background-color: $purple;
    margin: 0 auto;
    z-index: 5;
    left: 0;
    right: 0;
    transform: translateY(50%);
  }
}

.Line--double {
  &:before {
    content: "";
    position: relative;
    display: block;
    top: 0;
    height: 100px;
    width: 2px;
    background-color: $purple;
    margin: 0 auto;
    z-index: 5;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
}

.container {
  @include container();
}

.row {
  @include row();
}

.is-hidden {
  @include isHidden();
}

.is-bold {
  @include isBold();
}

.is-orange {
  @include isOrange();
}

.is-centered {
  @include isCentered();
}

%full-width {
  @include fullWidth();
}

%to-center {
  @include toCenter();
}
