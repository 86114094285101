@import "./src/style/theme";

.JobOffersSearchBar {
  display: flex;
  flex-direction: column;
  width: 30%;

  @media (max-width: $min-width-st) {
    width: 50%;
  }

  @media (max-width: $min-width-xs) {
    flex-grow: 1;
  }

  &-keywordsInfo {
    @include a11y-hide();
  }

  &-inputLabel {
    @include font(input-label);
    color: $dark-charcoal;
    margin-bottom: 6px;
  }

  &-inputWrapper {
    position: relative;

    &::after {
      content: "";
      background-image: url("../../../../assets/img/search.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 16px;
      height: 100%;
      position: absolute;
      bottom: 0;
      right: 16px;
      cursor: text;
      z-index: 1;
    }
  }

  &-input {
    @include font(input);
    background-color: $white;
    border-radius: $input-radius;
    border: 1px solid $silver-sand;
    color: $dark-charcoal;
    min-height: $input-height;
    outline: none;
    padding-left: $ds-spacing-200;
    padding-right: $ds-spacing-400;
    position: relative;
    width: 100%;

    &:focus {
      @include outline(-2px);
    }

    &::placeholder {
      @include font(input-placeholder);
      color: $sonic-silver;
    }
  }
}

.tabActive {
  .JobOffersSearchBar {
    &-inputWrapper:focus-within {
      &::before {
        @include outline(-2px);
      }
    }
  }
}
