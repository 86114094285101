@import "./src/style/theme";

.ThreeImages {
  position: relative;
  height: 600px;
  width: 50%;
  margin-top: 50px;

  @media (max-width: $max-width-xs) {
    width: 100%;
  }

  @media (max-width: $max-width-xxs) {
    height: 400px;
  }
}

.ThreeImages-imgWrapper {
  position: relative;
  height: 440px;
  width: 250px;
  overflow: hidden;

  @media (max-width: $max-width-xxs) {
    height: 280px;
    width: 160px;
  }
}

.ThreeImages:after {
  transform: translate(0, -20%);
  content: "";
  position: absolute;
  display: block;
  width: 250px;
  height: 440px;
  z-index: 1;
  background: $purple;
}

.ThreeImages:after,
.ThreeImages-imgWrapper--first,
.ThreeImages-imgWrapper--second {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  position: absolute;

  @media (max-width: $max-width-xxs) {
    width: 150px;
    height: 240px;
  }

  @media (max-width: $max-width-xxs) {
    width: 150px;
    height: 240px;
  }
}

.ThreeImages-imgWrapper--first {
  transform: translate(40%, 5%);
}

.ThreeImages-imgWrapper--second {
  transform: translate(-40%, -5%);
  z-index: 2;
}

.ThreeImages-image {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: inherit;
}

.ThreeImages--offset_340 {
  left: -340px;

  @media (max-width: $max-width-xxs) {
    left: -175px;
  }
}

.ThreeImages--offset_390 {
  left: -390px;

  @media (max-width: $max-width-xxs) {
    left: -210px;
  }
}
