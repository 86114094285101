@import "./src/style/theme";

.CareerModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.CareerModal-open {
  overflow: hidden;
}

.CareerModal-closeButton {
  @include reset-button();
  cursor: pointer;
  background: $white url("../../../assets/icons/close.svg") no-repeat center;
  background-size: 20px 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -50px;
  margin-right: -50px;

  @media (max-width: $min-width-sm) {
    margin-right: 0;
  }
}

.CareerModal-body {
  position: relative;
}
