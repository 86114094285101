@import "./src/style/theme";

.SendCVWidget {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  max-width: 420px;
  margin: auto;
  color: $dark-charcoal;
  cursor: default;
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.15);
  background-color: $white;

  @media (max-width: $min-width-st) {
    margin: 36px auto;
  }

  @media (max-width: $max-width-xxs) {
    max-width: 90%;
  }

  &-logoContainer {
    display: none;
    height: 69px;

    @media (max-width: $min-width-st) {
      display: block;
      padding: 0 32px;
    }
  }

  &-logoLink {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-logo {
    display: block;
    width: 116px;
    margin: 14px auto 8px;
  }

  &-header {
    @include font(send-cv-cta-button);
    font-weight: normal;
    text-align: center;
    margin-bottom: 32px;

    @media (max-width: $max-width-md) {
      text-align: left;
      margin-bottom: 0;
      line-height: 24px;
    }
  }

  &-boldText {
    font-weight: $font-p-bold;
    @media (max-width: $max-width-md) {
      white-space: pre;
    }
  }

  &-infoContainer {
    position: relative;
  }
  &-ctaBlock {
    padding: 60px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: $max-width-md) {
      flex-direction: row;
      padding: 20px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        height: 1px;
        background: $bright-gray;
        width: calc(100% - 40px);
        margin: auto;
      }
    }
  }

  .SendCV-button {
    margin: 0 auto;

    span {
      vertical-align: middle;
    }
  }

  &-recruiterBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $bright-gray;
    padding: 24px 32px;
  }

  &-recruiterImg {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;

    @media (max-width: $max-width-xxs) {
      width: 70px;
      height: 70px;
    }
  }

  &-recruiterTextBlock {
    @include font(widget-cta-text);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
  }

  &-recruiterName {
    @include font(send-cv-cta-button);
    text-align: left;
  }

  &-recruiterLinkedLink {
    margin-left: 50px;

    @media (max-width: $max-width-md) {
      margin-left: 8px;
    }

    @media (max-width: $max-width-xxs) {
      margin-left: 40px;
    }
  }

  &-recruiterLinkedImg {
    width: 30px;
    display: block;
    border-radius: 4px;
  }
}

.SendCVWidget-recruiterBlock {
  @media (max-width: $min-width-st) {
    max-width: 370px;
    margin: auto;
    padding: 36px 0 0;
    border-top: none;
  }

  @media (max-width: $max-width-xxs) {
    max-width: calc(100% - 50px);
  }
}
