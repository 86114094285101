@import "./src/style/theme";

.CarouselWithPictures {
  .Slide {
    height: auto;
    max-height: 400px;
    width: 100%;
  }

  .Slide-img-wrapper {
    background-position: center center;
    background-size: cover;
    background-clip: content-box;

    .Slide-img {
      opacity: 0;
    }
  }

  .Slide-img {
    height: 189px;
  }

  .slick-list {
    width: inherit;
  }

  .slick-arrow {
    width: 53px;
    height: 106px;
    background-color: rgba(0, 0, 0, 0.38);
    -webkit-appearance: none;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    top: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    z-index: 9;
    margin: auto;

    &:before {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      box-sizing: border-box;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
      left: 0;
    }

    &:focus {
      outline: none;
    }
  }

  .slick-next {
    right: 0;
    border-radius: 106px 0 0 106px;

    &:before {
      border-right: 4px solid $white;
      border-top: 4px solid $white;
      transform: rotate(48deg) skewY(-8deg);
    }
  }

  .slick-prev {
    left: 0;
    border-radius: 0 106px 106px 0;

    &:before {
      border-left: 4px solid $white;
      border-bottom: 4px solid $white;
      transform: rotate(48deg) skewY(-8deg);
    }
  }

  .tabActive {
    .slick-arrow:focus {
      @include outline();
    }
  }

  .slick-slide {
    & > div {
      height: auto;
      line-height: 1;

      & > div {
        vertical-align: bottom;
        font-size: 0;
      }
    }
  }
}
