@import "./src/style/theme";

.JobOffersMainFilter {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
  width: 200px;

  &-extraInfo {
    @include a11y-hide();
  }

  @media (max-width: $min-width-st) {
    width: 100%;
    margin-top: 32px;
  }

  &-label {
    @include font(input-label);
    color: $dark-charcoal;
    margin-bottom: 6px;
  }

  .Select {
    @include font(input);
    color: $dark-charcoal;

    &__menu-list {
      padding: 0;

      @media screen and (max-width: 1024px) {
        max-height: 140px;
      }
    }

    &__placeholder {
      @include font(input-placeholder);
      color: $sonic-silver;
      border: none;
      grid-area: 1/1/2/3;
      margin-left: 2px;
      margin-right: 2px;
      box-sizing: border-box;
    }

    &__single-value {
      @include font(input);
    }

    &__input-container {
      margin: 0;
      padding-bottom: 0;
      padding-top: 0;
    }

    &__value-container {
      padding-left: $ds-spacing-200;
      padding-right: 0;
    }

    &__dropdown-indicator {
      color: $black;
      padding-right: $ds-spacing-200;

      & svg {
        width: 16px;
        height: 16px;
      }
    }

    &__control {
      cursor: pointer;
      background-color: $white;
      border: solid 1px $silver-sand;
      -webkit-border-radius: $input-radius;
      -khtml-border-radius: $input-radius;
      -moz-border-radius: $input-radius;
      border-radius: $input-radius;
      overflow: hidden;
      box-shadow: none !important;
      min-height: $input-height;

      &:hover {
        border-color: $silver-sand;
      }

      &--is-focused {
        border-color: $silver-sand;
        -webkit-border-radius: $input-radius;
        -khtml-border-radius: $input-radius;
        -moz-border-radius: $input-radius;
        border-radius: $input-radius;
        overflow: hidden;
        @include outline(-2px);
      }
    }

    &__input {
      caret-color: transparent;
    }

    &__value {
      background-color: $white;
      border: 1px solid rgba(0, 0, 0, 0);
      border-bottom: none;
    }

    &__option {
      @include font(select-option);
      border: 1px solid $bright-gray;
      border-bottom: 0;
      height: 48px;
      cursor: pointer;
      box-sizing: border-box;

      &:last-child {
        border-radius: 0 0 10px 10px;
        border-bottom: 1px solid $bright-gray;
      }

      &:first-child {
        border-radius: 10px 10px 0 0;
      }

      &:hover,
      &--is-focused {
        background-color: $white;
        border: 1px solid $purple;
      }

      &--is-selected {
        @include font(select-option-selected);
        color: $dark-charcoal;
        background-color: $white;
      }
    }

    &__menu {
      border: 0;
      border-radius: 10px;
      box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.11);
      margin-top: 3px;
      margin-bottom: 3px;

      @media (max-width: $min-width-st) {
        height: 140px;
      }

      &-portal {
        @media (max-width: $min-width-st) {
          position: unset;
        }
      }
    }
  }

  .Select__option:first-child:hover,
  .Select__option:first-child.Select__option--is-focused,
  .Select__option:last-child:hover,
  .Select__option:last-child.Select__option--is-focused {
    background-color: $white;
    border: 1px solid $purple;
    border-radius: 10px 10px 0 0;
  }

  .Select__option:last-child:hover,
  .Select__option:last-child.Select__option--is-focused {
    border-radius: 0 0 10px 10px;
  }
}

@media (max-width: $min-width-st) {
  .JobOffersMainFilter:nth-last-child(3) {
    z-index: 4;
  }
}

.tabActive {
  .Select:focus-within {
    @include outline(-2px);
  }
}