@import "./src/style/theme";

.ParagraphText {
  cursor: default;
  color: $dark-charcoal;
  background-color: $white;

  @media (max-width: $min-width-st) {
    border-bottom: none;
  }

  &:nth-of-type(2) {
    padding-top: 20px;
  }

  &-wrapper {
    width: 70%;
    position: relative;
    padding: $ds-spacing-400 0;

    &:before {
      content: "";
      background: $bright-gray;
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 52px;
    }

    @media (max-width: $min-width-st) {
      width: 90%;
    }
    margin: 0 auto;
  }

  &-title {
    @include font(paragraph-text-title);
    padding-bottom: $ds-spacing-375;
  }

  &-text,
  &-list {
    @include font(widget-cta-text);
  }

  &-text {
    p {
      margin-bottom: $ds-spacing-175;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  &-list {
    li {
      position: relative;
      padding-left: $ds-spacing-400;

      &::before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        top: 5px;
        background-image: url("../../../../assets/icons/checkmark-icon.svg");
      }
    }
  }
}
