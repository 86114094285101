@import "./src/style/theme";

.ConsentCheckbox {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: $ds-spacing-200;
}

.ConsentCheckbox-checkbox {
  @include a11y-hide();
}

.ConsentCheckbox-isRequired {
  margin-right: $ds-spacing-150;
  line-height: $ds-spacing-200;
  color: $validation-red;
}

.ConsentCheckbox-span-isRequired {
  position: relative;

  &:before {
    position: absolute;
    content: "*";
    line-height: $ds-spacing-200;
    color: $validation-red;
    left: -$ds-spacing-175;
    top: 0;
  }
}

.ConsentCheckbox-text {
  @include font(consent-text);
}

.ConsentCheckbox-label {
  position: relative;
  padding-left: 32px;
  display: inline-block;
  cursor: pointer;

  &:after {
    content: "";
    display: block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    border: 1px solid $dark-charcoal;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.ConsentCheckbox-label--checked {
  &:before {
    @include font(button);
    color: $purple;
    content: "\2713";
    position: absolute;
    top: 1px;
    left: 4px;
  }
}

.ConsentCheckbox-checkbox:focus + .ConsentCheckbox-label {
  outline: none;

  &:after {
    border: 2px solid $purple;
  }
}
