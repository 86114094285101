@import "./src/style/theme";

.Popups {
  position: fixed;
  z-index: 5;
  bottom: 25px;
  right: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  @media (max-width: $max-width-xxs) {
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.Popups-item {
  margin-left: 20px;
  margin-top: 20px;

  @media (max-width: $max-width-xxs) {
    margin-left: 0;
    width: 100%;
  }
}
